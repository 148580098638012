import React from "react"
import { Link } from "gatsby"

function Navbar(props) {
  return (
    <header className="header navbar navbar-expand-lg navbar-dark bg-info navbar-sticky">
      <div className="container px-0 px-xl-3">
        <button className="navbar-toggler ms-n2 me-2" type="button" data-bs-toggle="offcanvas" data-bs-target="#primaryMenu">
          <span className="navbar-toggler-icon" />
        </button>
        <Link to={"/"} className="navbar-brand flex-shrink-0 order-lg-1 mx-auto ms-lg-0 pe-lg-2 me-lg-4" href="">
          Aidan Charles Serra
        </Link>
        <div className="offcanvas offcanvas-collapse order-lg-2" id="primaryMenu">
          <div className="offcanvas-header navbar-shadow">
            <h5 className="mt-1 mb-0">Menu</h5>
            <button className="btn-close lead" type="button" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div className="offcanvas-body">
            {/*Menu*/}
            <ul className="navbar-nav">
              <li className="nav-item dropdown">
                <a href={"/#myBooks"} className="nav-link">My Books</a>
              </li>
              <li className="nav-item dropdown">
                <a href={"/#gibraltarOrder"} className="nav-link">
                  Reserve Form
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Navbar
